import urql, { cacheExchange, fetchExchange, mapExchange } from '@urql/vue'
import { authExchange as AuthExchange } from '@urql/exchange-auth'
import ErrorHandler from '~/apollo/error-handler'

function isAuthenticationError(errorContext) {
  const isUnauthenticated = errorContext?.graphQLErrors?.some(gqlError => gqlError.extensions.code === 'UNAUTHENTICATED')

  Boolean(isUnauthenticated || errorContext.response.status === 401)
}

function getAuthExchange() {
  return AuthExchange(async(utils) => {
    // `getToken` is a function that returns the token being used by Apollo Client.
    // This was done to ensure that the Apollo Client and URQL Client use the same token for authentication.
    // This should be reworked to store the token in this plugin's state or some other store once Apollo Client is removed.
    // One of the challenges with using Apollo Client as the store is that the token might not be available when this exchange is called.
    // To combat this the `willAuthError` option was added below which will check for the auth token before making requests.
    // If there is no auth token then the `refreshAuth` function will be automatically called by this exchange before making the request.
    // Note that this `willAuthError` option should be here regardless of Apollo Client, and this is just an explanation of how it was used to resolve this issue.
    const { getToken } = useApollo()
    let token = await getToken()

    return {
      addAuthToOperation(operation) {
        if (!token) {
          return operation
        }

        return utils.appendHeaders(operation, {
          Authorization: `Bearer ${token}`,
        })
      },
      willAuthError() {
        // If the token is not set then the request will result in an error.
        return Boolean(!token)
      },
      didAuthError(errorContext, _operation) {
        // Returning `true` from this function will result in `refreshAuth` below being called by the exchange.

        return isAuthenticationError(errorContext)
      },
      // When `refreshAuth` is called for an operation URQL's auth exchange automatically flags the operation as having its authentication refresh attempted.
      // After this function runs the auth exchange adds the operation back into the request queue to retry to request.
      // The auth exchange will only call `refreshAuth` if the operations has NOT already be flagged for attempting an authentication refresh.
      // This means that each operation that fails due to authentication will only automatically retry once instead of creating an infinite loop.
      // There is also a retry exchange that is maintained by URQL. However, that exchange is for retrying requests NOT caused by failing to authenticate.
      async refreshAuth() {
        const newToken = await getToken()

        if (newToken) {
          token = newToken
        }
      },
    }
  })
}

export default defineNuxtPlugin((nuxtApp) => {
  const { public: config } = useRuntimeConfig()

  const authExchange = getAuthExchange()

  nuxtApp.vueApp.use(urql, {
    url: config.graphqlServerEndpoint,
    // The order of exchanges matters as synchronous exchanges must be placed before asynchronous exchanges.
    // See https://commerce.nearform.com/open-source/urql/docs/advanced/authoring-exchanges/#synchronous-first-asynchronous-last for details.
    exchanges: [
      cacheExchange,
      mapExchange({
        onError(errorContext, operation) {
          if (isAuthenticationError(errorContext)) {
            nuxtApp.$store.dispatch('self/logout')
          }

          // `skipGlobalErrorHandling` is an option that can be provided to clients and individual URQL requests.
          // If true then skip the global error handler behavior.
          if (operation.context.skipGlobalErrorHandling) {
            return
          }

          // TODO: Once Apollo Client is removed from Portal Next these error handling functions should be refactored to avoid this unclear mapping.
          ErrorHandler(errorContext, nuxtApp)
        },
      }),
      authExchange,
      fetchExchange,
    ],
  })
})
