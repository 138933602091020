export default {
  clearPropertyDamageQuestions({ commit }) {
    commit('setAnyUnfinishedDamagedRooms', null)
    commit('setHasExteriorDamage', null)
    commit('setNumberRoomsDamaged', null)
    commit('setNumberStructuresDamaged', null)
    commit('setNumberItemsDamaged', null)
    commit('setDescriptionOfDamagedProperty', '')
  },
  clearDamages({ commit, dispatch }) {
    commit('setDoesKnowSourceOfDamage', null)
    commit('setSourceOfDamage', null)
    commit('resetAdditionaQuestions')
    commit('setPropertyDamages', [])
    dispatch('clearPropertyDamageQuestions')
  },
  clearPartnerContactOptIns({ commit, state }) {
    state.partnerContactOptIns.forEach((optIn) => {
      commit('setPartnerContactOptInsStatus', { task: optIn.task, canContact: null })
    })
  },
  clearApplicationState({ commit, dispatch }) {
    commit('resetContacts')
    commit('setSelectedReporter', null)
    commit('setReporterOptsIntoEmail', null)
    commit('setReporterOptsIntoText', null)
    dispatch('clearPartnerContactOptIns')
    commit('setVendorAssignments', [])
    commit('setTaskAssignments', [])
    commit('resetNotes')
    commit('setDamageType', null)
    commit('setDateOfLoss', '')
    commit('setDateOfNotification', new Date())
    commit('setNotificationMethod', 'phone')
    commit('setDescriptionOfLoss', '')
    commit('setCauseOfDamage', null)
    commit('clearAttachments')
    commit('setContactsTabHasErrors', true)
    commit('setDamageTabHasErrors', true)
    commit('setHasVisitedDamageTab', false)
    commit('setHasAttemptedReviewScreen', false)
    commit('setClaimNumber', '')
    commit('setClaimReferenceNumber', '')
    commit('setShowClaimSuccessDialog', false)
    dispatch('clearDamages')
  },
  updateMappedContacts({ commit, state }, id) {
    // filters out the deleted contactID
    const updatedSet = (stateValue) => {
      return state.additional[stateValue].filter((val) => {
        return val !== id
      })
    }
    // define additional question fields that should be updated when a contact is deleted
    const mappedContactsToCheck = ['mitigationContactIds', 'propertyOwnerContactIds', 'injuredPersonContactIds']

    mappedContactsToCheck.forEach((field) => {
      // if the deleted contact is in the mapped array on state
      if (state.additional[field].includes(id)) {
        // create the new set (removes the deleted contact id)
        const newValue = updatedSet(field)
        const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1)
        // commit the mutation with the updated array
        commit(`set${capitalizedField}`, newValue)
      }
    })
  },
}
