import { collectAttachmentNames, collectUserNotes } from './createClaimPayload'

export default (state, policyNumber, isAgent) => {
  return new Promise((resolve) => {
    // This payload is identical to createClaimPayload but doesn't include `partnerContactOptIns`
    // TODO: Use createClaimPayload again for /applicableAssignments calls. Duplicating this file is a workaround to avoid including `partnerContactOptIns` in the /applicableAssignments `useQuery` data param, where it creates an unintended reactivity chain which leads to over-eager querying. We don't advocate for this approach in other instances
    const payload = {
      policyNumber,
      contacts: state.contacts,
      propertyDamage: {
        ...state.propertyDamage,
      },
      dateOfLoss: state.dateOfLoss,
      damageType: state.damageType,
      causeOfDamage: state.causeOfDamage,
      descriptionOfLoss: state.descriptionOfLoss,
      dateOfNotification: state.dateOfNotification,
      notificationMethod: isAgent ? 'online' : state.notificationMethod,
      reporterOptsInToText: isAgent ? true : state.reporterOptsIntoText,
      reporterOptsInToEmail: isAgent ? true : state.reporterOptsIntoEmail,
      userGeneratedNotes: state.notes.length ? collectUserNotes(state) : null,
      attachments: state.attachments.length ? collectAttachmentNames(state) : null,
      additionalDetails: {
        incidentLocation: {
          ...state.additional.incidentLocation,
        },
        isHomeSafe: state.additional.isHomeSafe,
        didLosePower: state.additional.didLosePower,
        wasArrestMade: state.additional.wasArrestMade,
        wasReportFiled: state.additional.wasReportFiled,
        wasRoofDamaged: state.additional.wasRoofDamaged,
        isWaterTurnedOff: state.additional.isWaterTurnedOff,
        hasStandingWater: state.additional.hasStandingWater,
        hasLawsuitBeenFiled: state.additional.isLawsuitFiled,
        didContactPlumber: state.additional.didContactPlumber,
        wasHomeBrokenInto: state.additional.wasHomeBrokenInto,
        didAdviseOnPlumber: state.additional.didAdviseOnPlumber,
        werePoliceNotified: state.additional.werePoliceNotified,
        didTurnoffStopWater: state.additional.didTurnoffStopWater,
        mitigationContactIds: state.additional.mitigationContactIds,
        didContactMitigation: state.additional.didContactMitigation,
        didFireDepartmentCome: state.additional.didFireDepartmentCome,
        descriptionOfTreatment: state.additional.descriptionOfTreatment,
        hasHolesInDoorsWindows: state.additional.hasHolesInDoorsWindows,
        propertyOwnerContactIds: state.additional.propertyOwnerContactIds,
        hasResultingWaterDamage: state.additional.hasResultingWaterDamage,
        injuredPersonContactIds: state.additional.injuredPersonContactIds,
        didReceiveMedicalTreatment: state.additional.didReceiveMedicalTreatment,
        didIncidentOccurAtProperty: state.additional.didIncidentOccurAtProperty,
        hasPropertyOwnerInformation: state.additional.hasPropertyOwnerInformation,
        isImmediateAssistanceNeeded: state.additional.isImmediateAssistanceNeeded,
        hasInjuredPersonsInformation: state.additional.hasInjuredPersonsInformation,
        hasAttorney: state.additional.injuredHasAttorney || state.additional.ownerHasAttorney,
        sourceOfWater: state.doesKnowSourceOfDamage && state.causeOfDamage === 'water' ? state.sourceOfDamage : '',
        sourceOfFire: state.doesKnowSourceOfDamage && state.causeOfDamage === 'fire' ? state.sourceOfDamage : '',
      },
    }

    resolve(payload)
  })
}
